import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Kyle Robertson | Composer" />
    <a href="mailto:kyle@kylerobertson.com">kyle@kylerobertson.com</a>
    <p>brb...</p>
  </Layout>
)

export default IndexPage
